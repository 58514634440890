import React from "react"
import { Link, graphql } from "gatsby"
import DefaultLayout from "../layouts/default"
import SEO from "../components/seo"
import { groupBy, getDateYear } from "../utils"
import ImageMasonry from "react-image-masonry"

const Blog = ({ data }) => {
  // all posts without dates are assumed to be drafts or pages
  // not to be added to postsList
  const posts = data.allMarkdownRemark.edges.filter(
    p => p.node.frontmatter.date !== null
  )
  const postsList = posts =>
    posts.map(post => (
      <li key={post.node.id}>
        <div className="title">
          <Link to={post.node.fields.slug}>{post.node.frontmatter.title}</Link>
        </div>
      </li>
    ))

  const postsListContainer = groupBy(posts, getDateYear)
    .map(({ posts }, i) => <div key={i}>{postsList(posts)}</div>)
    .reverse()

  return (
    <DefaultLayout>
      <SEO title="Blog" />
      <section className="home__intro-container">
        <h1>I like building stuff 🛠️</h1>
        <h2>
          In my free time, I enjoy experimenting with creative programming,
          electronics, and 3D printing.
        </h2>
        <div className="home__divider"></div>
        <h3>Check out some of my projects:</h3>
        <ul>{postsListContainer}</ul>
      </section>
      <section>
        <ImageMasonry
          imageUrls={[
            "https://storage.googleapis.com/maker-blog-assets/lego-led-clock/lego-cisco.jpeg",
            "https://storage.googleapis.com/maker-blog-assets/illustrations/ultrasonic-sensor.jpg",
            "https://storage.googleapis.com/maker-blog-assets/3d-model/first-model.png",
            "https://storage.googleapis.com/maker-blog-assets/maker-cat.jpg",
            "https://storage.googleapis.com/maker-blog-assets/lego-led-clock/clock-WIP.jpg",
            "https://storage.googleapis.com/maker-blog-assets/avoiding-robot/robot.jpg",
            "https://storage.googleapis.com/maker-blog-assets/lego-led-clock/breadboard.jpg",
            "https://storage.googleapis.com/maker-blog-assets/lego-led-clock/lego-clock.jpg",
            "https://storage.googleapis.com/maker-blog-assets/3d-model/mecabricks.png",
          ]}
          numCols={3}
        />
      </section>
    </DefaultLayout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YY")
            title
          }
        }
      }
    }
  }
`
